.plugin_contrast_button {
    cursor: pointer;
}

.page_contrast {
    .menu_btn > div {
        background-color:yellow!important;
    }

    .ico {
        filter:brightness(10);
    }
}




.plugin_font_sizes {
    white-space:nowrap;

    > li {
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        margin-right: 5px;

        a {
            cursor: pointer;
            color: #050505;
            font-weight: 500;
        }

        &:last-child{
            margin-right: 0;
        }
        &.font_large{
            font-size: 14.4px;
            line-height: 22px;
            padding-top: 0px;
        }
        &.font_normal{
            font-size: 14.4px;
            line-height: 22px;
            padding-top: 0px;
        }
        &.font_small{
            font-size: 14.4px;
            line-height: 22px;
            padding-top: 0px;
        }
    }
}
